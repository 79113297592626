import React, { Fragment } from 'react'

import { Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import Layout from '../components/layout/Layout'

export default function Home() {
	const paths = [
		{ title: 'Post', path: '/post' },
		{ title: 'CTA Widget', path: '/cta-widget' },
		{ title: 'Top Sticky Bar', path: '/top-sticky-bar' },
		{ title: 'My Finance', path: '/my-finance' },
		{ title: 'Tracking', path: '/tracking' },
		{ title: 'Sponsored Block', path: '/sponsored-block' },
		{ title: 'Common Block', path: '/common-block' },
		{ title: 'Top Offers', path: '/top-offers' },
		{ title: 'Our Picks', path: '/our-picks' },
	]

	return (
		<Layout>
			<div className="Home">
				{paths.map(({ title, path }) => (
					<Button className="btn" key={path} variant="outlined" to={path} component={RouterLink}>
						{title}
					</Button>
				))}
			</div>
		</Layout>
	)
}

