import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { getRichTextEntityLinks } from '@contentful/rich-text-links'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { nanoid } from 'nanoid'

import { CONTENTFUL_ENV, CONTENTFUL_SPACE } from '../utils/constants'
import { getCategories as cacheCategories, getSubCategories as cacheSubCategories, getCategorization } from './categorization'
import { getCommonBlocksMap } from './common-block'
import { connectCM } from './contentful'
import { getCTAWidgetsMap } from './cta-widget'
import { getMyFinanceMap } from './my-finance'
import { getOurPicksMap } from './our-picks'
import { fillWidgetWithPosts, getLinkedPosts } from './posts'
import { getAllWidgets as cacheSponsoredBlocks, getCategorizationSponsoredBlock, getSponsoredBlocksMap } from './sponsored-block'
import { getTopOffersMap } from './top-offers'
import { getTopStickybarsMap } from './top-sticky-bar'
import { getTrackingsMap } from './tracking'

const PAGE_SIZE = 50
const MAX_NUMBER_OF_PAGES = null //for test only
let CACHED_POSTS = null
let CACHED_POSTS_MAP = null
const domParser = new DOMParser()

export async function getAllPosts() {
	if (CACHED_POSTS) return CACHED_POSTS
	let posts = []
	const firstPage = await getPostsPage(0)
	posts.push(...firstPage.items)
	const numberOfPages = MAX_NUMBER_OF_PAGES || Math.ceil(firstPage.total / PAGE_SIZE)
	for (let i = 1; i < numberOfPages; i++) {
		let pagePosts = await getPostsPage(i)
		posts.push(...pagePosts.items)
	}
	CACHED_POSTS_MAP = createPostsMap(posts)
	CACHED_POSTS = await mapPosts(posts)
	return CACHED_POSTS
}

async function getPostsPage(pageIndex) {
	const filter = {
		'content_type': 'post',
		'limit': PAGE_SIZE,
		'skip': pageIndex * PAGE_SIZE,
		'fields.unpublishedPost': false,

		// 'sys.id': '3GxnGiCf8kCGN829DtE4S6', //temp
		'include': 0,
	}

	const contentful = await connectCM()

	const response = await contentful.getPublishedEntries(filter)

	return response
}

async function mapPosts(_posts) {
	try {
		await cacheSponsoredBlocks()
		await cacheCategories()
		await cacheSubCategories()

		const ctaWidgetsMap = await getCTAWidgetsMap()
		const myFinanceMap = await getMyFinanceMap()
		const commonBlocksMap = await getCommonBlocksMap()
		const sponsoredBlocksMap = await getSponsoredBlocksMap()
		const trackingsMap = await getTrackingsMap()
		const topStickybarsMap = await getTopStickybarsMap()
		const topOffersMap = await getTopOffersMap()
		const ourPicksMap = await getOurPicksMap()

		const contentfulBacklinks = await getContentfulBacklinks(_posts)

		let posts = _posts
			.map(i => ({
				id: i.sys.id,
				title: i.fields?.title['en-US'],
				published: i.fields.updatedDate?.['en-US'] ? new Date(i.fields.updatedDate?.['en-US']) : new Date(i.fields.publishDate?.['en-US']),
				slug: i.fields.slug['en-US'],
				contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${i.sys.id}`,
				url: `https://joywallet.com/article/${i.fields.slug['en-US']}/`,
				richBody: i.fields?.richBody['en-US'],
				body: documentToPlainTextString(i.fields.richBody['en-US']),
				links: getRichTextEntityLinks(i.fields?.richBody['en-US'])?.Entry?.map(l => l.id),
				outgoingLinks: getOutgoingLinks(documentToHtmlString(i.fields?.richBody['en-US']), i.sys.id),
				topSticyBarId: i.fields.topStickyBar?.['en-US']?.sys.id,
				...getCategorization(i.fields.categorization?.['en-US']?.sys.id), //returns {categoryId & subCategoryId}
			}))
			.map(p => ({
				...p,
				ctaWidgets: p.links.filter(id => ctaWidgetsMap.get(id)).map(id => ctaWidgetsMap.get(id)),
				topOffers: p.links.filter(id => topOffersMap.get(id)).map(id => topOffersMap.get(id)),
				myFinance: p.links.filter(id => myFinanceMap.get(id)).map(id => myFinanceMap.get(id)),
				ourPicks: p.links.filter(id => ourPicksMap.get(id)).map(id => ourPicksMap.get(id)),
				commonBlocks: p.links.filter(id => commonBlocksMap.get(id)).map(id => commonBlocksMap.get(id)),
				trackings: p.links.filter(id => trackingsMap.get(id)).map(id => trackingsMap.get(id)),
				posts: p.links.filter(id => CACHED_POSTS_MAP.get(id)).map(id => CACHED_POSTS_MAP.get(id)),
				sponsoredBlocks: [
					...p.links.filter(id => sponsoredBlocksMap.get(id)).map(id => sponsoredBlocksMap.get(id)),
					...getCategorizationSponsoredBlock(p.categoryId, p.subCategoryId),
				],

				topStickybars: topStickybarsMap.get(p.topSticyBarId) ? [topStickybarsMap.get(p.topSticyBarId)] : [],
				internalLinks: p.outgoingLinks.internalLinks,
				externalLinks: p.outgoingLinks.externalLinks,
				contentfulBacklinks: contentfulBacklinks.get(p.id),
				copyTitle: p.title,
				copySlug: p.slug,
				copyUrl: p.url,
			}))

		const urlBacklinks = handleUrlBacklinks(posts)

		posts = posts.map(p => ({
			...p,
			name: p.title,
			backlinksCount: (p.contentfulBacklinks?.length || 0) + (urlBacklinks.get(p.id)?.length || 0),
			outgoingLinksCount: (p.posts?.length || 0) + (p.trackings?.length || 0) + (p.internalLinks?.length || 0),
			ctaWidgetsCount: p.ctaWidgets?.length || 0,
			nodes: [
				...p.ctaWidgets,
				...p.topStickybars,
				...p.topOffers,
				...p.myFinance,
				...p.ourPicks,
				...p.commonBlocks,
				...p.sponsoredBlocks,
				...p.trackings,
				...p.posts,
				...p.internalLinks,
				...p.externalLinks,
				...p.contentfulBacklinks,
				...(urlBacklinks.get(p.id) || []),
			].map(w => ({
				id: w.id,
				title: w.displayName,
				contentful: w.contentful,
				url: w.url,
			})),
		}))

		return posts
	} catch (err) {
		console.error(err)
		return []
	}
}

function createPostsMap(posts) {
	const map = new Map()
	posts.map(p =>
		map.set(p.sys.id, {
			id: p.sys.id,
			displayName: `Post Link - ${p.fields?.title['en-US']}`,
			contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${p.sys.id}`,
		}),
	)
	return map
}

function getOutgoingLinks(html, postId) {
	const dom = domParser.parseFromString(html, 'text/html')

	const hrefs = [...dom.querySelectorAll('a')].map(a => a.getAttribute('href'))

	const uniqHrefs = _.uniq(hrefs)

	let internalLinks = []
	let externalLinks = []

	uniqHrefs.forEach(a => {
		try {
			const isInternal = new URL(a).hostname.includes('joywallet')

			if (isInternal) internalLinks.push(a)
			else externalLinks.push(a)
		} catch (err) {
			console.error(err, a, `at post ${postId}`)
		}
	})

	return {
		internalLinks: internalLinks.map(a => ({
			id: nanoid(),
			displayName: `Outgoing Internal Link - ${a}`,
			url: a,
		})),
		externalLinks: externalLinks.map(a => ({
			id: nanoid(),
			displayName: `Outgoing External Link - ${a}`,
			url: a,
		})),
	}
}

async function getContentfulBacklinks(posts) {
	const postIds = posts.map(p => ({
		id: p.sys.id,
		title: p.fields.title['en-US'],
		displayName: `Contentful Backlink - ${p.fields.title['en-US']}`,
		contentful: `https://app.contentful.com/spaces/${CONTENTFUL_SPACE}/environments/${CONTENTFUL_ENV}/entries/${p.sys.id}`,
		url: `https://joywallet.com/article/${p.fields.slug['en-US']}/`,
	}))

	const links = await getLinkedPosts(postIds)

	const arr = fillWidgetWithPosts({ widgets: postIds, links, nodes: postIds })

	const map = new Map()

	arr.map(p =>
		map.set(
			p.id,
			p.nodes.map(n => ({ ...n, id: nanoid() })),
		),
	)
	return map
}

function handleUrlBacklinks(posts) {
	let dictionary = []

	//only the pathname without traling slash, example: /article/understanding-fafsa
	posts.forEach(p =>
		p.internalLinks.forEach(l => {
			let slug = new URL(l.url).pathname.replace(/\/\s*$/, '') //remove last slash
			slug = slug.replace('/article/', '') //remove /article/
			slug = slug.indexOf('/') == 0 ? slug.substring(1) : slug //remove first slash
			dictionary.push({ sourceId: p.id, sourcePost: posts.find(pp => pp.id === p.id), destinationSlug: slug })
		}),
	)

	dictionary = dictionary
		.map(l => ({ ...l, destinationPost: posts.find(p => p.slug === l.destinationSlug), destinationId: posts.find(p => p.slug === l.destinationSlug)?.id }))
		.filter(l => l.destinationPost)

	const map = new Map()

	dictionary.forEach(l => {
		const exist = map.get(l.destinationId)

		//needs a fix //todo

		if (!exist) {
			map.set(l.destinationId, [
				{
					id: nanoid(),
					displayName: `URL Backlink - ${l.sourcePost.title}`,
					url: l.sourcePost.url,
					contentful: l.sourcePost.contentful,
				},
			])
		} else {
			exist.push({
				id: nanoid(),
				displayName: `URL Backlink - ${l.sourcePost.title}`,
				url: l.sourcePost.url,
				contentful: l.sourcePost.contentful,
			})
		}
	})

	return map
}

